
import template from './query.html';

/**
 * Query Page view model
 */
class QueryViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.query = ko.observable();
		this.query_refnr = ko.observable();
		this.selected_tab = ko.observable('contact_details');
		this.selected_types = ko.observable();
		this.selected_query = ko.observable();
		this.contact_uuid = ko.observable();
		this.contact = ko.observable();
		this.conversation_uuid = ko.observable();
		this.query_states = ko.observableArray();
		this.selected_query_state = ko.observable();
		this.message_categories = ko.observableArray();
		this.selected_category = ko.observableArray([]);
		this.contact_name = ko.observable('');
		this.inserted_on_date = ko.observable();

		this.contact_uuid.subscribe((val) => {
			if (val)
				this.page.get_contact();
		});

		this.selected_tab.subscribe((newVal) => {
			if (newVal === 'contact_details')
				this.selected_types(['Contact Details']);
			else if (newVal === 'Contact_queries')
				this.selected_types(['Contact Queries']);
		});

		this.query_refnr.subscribe((val) => {
			this.get_query();
		})
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#contacts-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});

		event.currentTarget.classList.add('active');

		this.selected_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	async get_query()
	{
		let query = await Grape.tables.select({
			schema:'queries',
			table:'v_queries',
			filter: [{
				field: 'query_refnr',
				operand: '=',
				value: this.query_refnr()
			}]
		});

		if (query.status !== 'OK')
			Grape.alerts.alert({ type: 'Error', message: 'Error occurred while loading queries for the specific contact.', title: 'Error'});

		let query_record = query.records[0];

		// format date
		if (query_record.inserted_on)
			query_record.inserted_on = new Date(query_record.inserted_on).toISOString().split('T')[0];

		this.query(query.records[0]);
		this.contact_uuid(query.records[0].contact_uuid || null);
		this.conversation_uuid(query.records[0].conversation_uuid || null);

		if (query_record.categories && query_record.categories.length > 0) {
			this.selected_category(query_record.categories);
		} else {
			this.selected_category([]);
		}

	}

	btn_back()
	{
		Grape.navigate('/ui/engagements/queries');
	}

	btn_to_contact_click()
	{
		Grape.navigate('[/ui/contacts/]dashboard', { contact: this.contact() });
	}
}

/**
 * Query page class
 */
class QueryClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new QueryViewModel(this);
		this.name = 'QueryClass';
	}

	async init()
	{
		// GET QUERY STATES
		let res_states = await Grape.cache.fetch('QueryStatesLookup');
		if (res_states.status !== 'ERROR')
			this.viewModel.query_states(res_states);

		// GET MESSAGE CATEGORIES
		let res_types = await Grape.cache.fetch('MessageCategory');
		if (res_types.status !== 'ERROR')
			this.viewModel.message_categories(res_types);

		if (this.bindings.data)
		{
			this.viewModel.query_refnr(ko.utils.unwrapObservable(this.bindings.data.query_refnr) || null);
			localStorage.setItem('last_visited_query', this.viewModel.query_refnr());
		}
		else
		{
			let query_refnr = localStorage.getItem('last_visited_query');
			if (query_refnr)
				this.viewModel.query_refnr(query_refnr);
		}
	}

	async updateData()
	{
		if (this.viewModel.query_refnr())
			await this.viewModel.get_query();
	}

	async get_contact()
	{
		if (this.viewModel.contact_uuid())
		{
			await Grape.fetches.getJSON(`api/contact?contact_uuid=${this.viewModel.contact_uuid()}`).then((res) => {
				this.viewModel.contact(res.contact);
			});

			let contact = this.viewModel.contact();
			if (contact)
			{
				let contactname = '';
				if (contact.personal_detail.firstname && contact.personal_detail.surname)
					contactname = `${contact.personal_detail.firstname} ${contact.personal_detail.surname}`;
				else if (contact.personal_detail.firstname)
					contactname = contact.personal_detail.firstname;
				else if (contact.personal_detail.surname)
					contactname = contact.personal_detail.surname;
				else if (contact.personal_detail.nickname)
					contactname = contact.personal_detail.nickname;
				else if (contact.general.email)
					contactname = contact.general.email;
				else if (contact.general.mobile_phone)
					contactname = contact.general.mobile_phone;
				else
					contactname = '';

				this.viewModel.contact_name(contactname);
			}
		}
	}

	async teardown()
	{}
}

export default {
	route: '[/]ui/engagements/query',
	page_class: QueryClass,
	template: template
}
