import template from './query-age-analysis-dashboard-panel.html';

class QueryAgeAnalysisDBPanelViewModel
{
	constructor(params)
	{
		this.params = params;

		console.log('params in age analysis panel: ', this.params);
		
		this.title = ko.observable('None');
		this.filter = ko.observable();
		this.time_span_interval = ko.observable(1);
		this.time_span = ko.observable('day(s)');

		this.init();

	}

	init ()
	{
		console.log('init in age analysis panel running');

		let filters = {
			filter: [
				{field: 'time_span_interval', 'operator': '=', value: this.time_span_interval()},
				{field: 'time_span', 'operator': '=', value: this.time_span()}
			],
			filter_join: 'AND'
		};

		// TODO add when no for DEMO
		// this.filter(filters);
		this.filter({});
	}

}


export default {
	name: 'query-age-analysis-dashboard-panel',
	viewModel: QueryAgeAnalysisDBPanelViewModel,
	module_type: 'ko',
	template: template
}
