
// COMPONENTS
// import sample_component from './sample-component/sample-component.js';

// SEARCH
import search_all_queries from './search-all-queries/search-all-queries.js';
import contact_queries_panel from './contact-queries-panel/contact-queries-panel.js';
import contact_queries from './contact-queries/contact-queries.js';
import contact_notes_panel from './contact-notes-panel/ko-contact-notes.js';
import contact_notes from './contact-notes/contact-notes.js';
import qrykpidbpanel from './query-kpi-dashboard-panel/query-kpi-dashboard-panel.js';
import qriageanapanel from './query-age-analysis-dashboard-panel/query-age-analysis-dashboard-panel.js';
import qrivolumespanel from './query-volumes-dashboard-panel/query-volumes-dashboard-panel.js';

export default [
	search_all_queries,
	contact_queries_panel,
	contact_queries,
	contact_notes_panel,
	contact_notes,
	qrykpidbpanel,
	qriageanapanel,
	qrivolumespanel/*,
	shipments,
	shipments_panel*/
];
