import template from './query-kpi-dashboard-panel.html';

class QueryKPIDBPanelViewModel
{
	constructor(params)
	{
		this.params = params;
		this.indicators = ko.observableArray([]);

		this.indicators([
			{title: 'OPEN - NEW', current_value: 27, avg_5day: 20.8},
			{title: 'OPEN - ALL', current_value: 53, avg_5day: 40.7},
			{title: 'WITHIN SLA PERIOD', current_value: '85%', avg_5day: '81%'},
			{title: 'OUTSIDE SLA PERIOD', current_value: '8', avg_5day: '5.5'},
			{title: 'AVERAGE TAT', subtitle: '1st TIME RESPONSE', current_value: '03:36', avg_5day: '03:07'},
		]);
	}
}


export default {
	name: 'query-kpi-dashboard-panel',
	viewModel: QueryKPIDBPanelViewModel,
	module_type: 'ko',
	template: template
}
