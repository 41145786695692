import template from './contact-notes.html';

class ContactNotesViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);
		this.editor;

		this.contact.subscribe((val) => {
			this.get_queries(val);
		});

		this.init();
	}

	async init()
	{
		await new Promise((resolve) => {
			this.editor = new FroalaEditor('#froala-editor', {
				key: Grape.froala.key,
				attribution: false,
				toolbarButtons:   [
					['bold', 'italic', 'underline', 'strikeThrough'],
					['fontFamily', 'fontSize', 'textColor', 'backgroundColor'],
					['inlineClass', 'inlineStyle', 'clearFormatting'],
					[ 'fullscreen']
				],
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});
	}

	async get_queries()
	{
	}

	async saveNoteClick ()
	{
        let note = this.editor.html.get();

		// TODO
		return;
		try
		{
			let result = await Grape.fetches.postJSON('url?', {});
			
			if (result.status != 'ERROR')
				Grape.alerts.alert({ type:'success', title: 'Success', message: 'Note saved successfully.' });
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}


export default {
	name: 'ko-contact-notes',
	viewModel: ContactNotesViewModel,
	module_type: 'ko',
	template: template
}