
import template from './query-list.html';
import Query from '../../lib/models/Query.js';

/**
 * Queries Page view model
 */
class QueriesViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.type = ko.observable(this.page.bindings.type);
		this.queries = ko.observableArray([]);
		this.title_visibility = ko.observable(true);
		this.title = ko.observable('All Queries');
		this.current_folder = ko.observable();
		this.active_nav = ko.observable(null);
		this.active_side_nav = ko.observable(null);
		this.active_side_nav_main = ko.observable(null);
		this.side_nav_visible = ko.observable(false);
		this.folders = ko.observable();
		this.selected_folder = ko.observable();
		this.search_text = ko.observable('');
		this.side_nav_items = ko.observableArray([]);
		this.sort_by_options = ko.observableArray(['Assignee', 'Participants', 'Channel', 'Date', 'Attachments', 'State']);
		this.selected_sort_option = ko.observable('assignee');
		this.sort_order = ko.observable('ASC');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.current_page_size.subscribe(async () => {
			await this.page.updateData();
		});
		this.page_size_options = ko.observableArray([10,20,50]);
		this.page_count = ko.observable(1);
		this.selected_queries = ko.observableArray();
		this.selected_queries_count = ko.computed(() => {
			return this.selected_queries().length;
		});

		//filtering options
		this.categories = ko.observableArray([]);
		this.channels = ko.observableArray([]);
		this.folders = ko.observableArray([]);
		this.states = ko.observableArray([]);
		this.users = ko.observableArray([]);

		this.filter = {
			filter_name: ko.observable(),
			public: ko.observable(),
			from_date: ko.observable(),
			to_date: ko.observable(),
			selected_categories: ko.observableArray([]),
			selected_users: ko.observableArray([]),
			selected_folders: ko.observableArray([]),
			selected_states: ko.observableArray([]),
			selected_channels: ko.observableArray([])
		};

		this.search_text.subscribe((newVal) => { 
			if (newVal && newVal != '')
			{
				this.filter.selected_users([]);
				this.filter.selected_states([]);
				this.filter.selected_categories([]);
				this.filter.selected_folders([]);
				this.filter.selected_channels([]);
				this.filter.from_date(null);
				this.filter.to_date(null);
				this.page.updateData();
			}
		});

		this.filter.from_date.subscribe((newVal) => { 
			if (newVal && newVal != null)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.to_date.subscribe((newVal) => { 
			if (newVal && newVal != null)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.selected_categories.subscribe((newVal) => { 
			if (newVal && newVal.length > 0)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.selected_users.subscribe((newVal) => { 
			if (newVal && newVal.length > 0)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.selected_folders.subscribe((newVal) => { 
			if (newVal && newVal.length > 0)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.selected_states.subscribe((newVal) => { 
			if (newVal && newVal.length > 0)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.filter.selected_channels.subscribe((newVal) => { 
			if (newVal && newVal.length > 0)
			{
				this.search_text('');
				this.page.updateData();
			}
		});

		this.all_selected_queries = ko.pureComputed({
			read: () => {
				return this.selected_queries().length === this.queries().length && this.queries().length > 0;
			},
			write: (value) => {
				if (value) {
					this.selected_queries(this.queries.slice());
				} else {
					this.selected_queries([]);
				}
			},
			owner: this
		});

		this.selected_filters_label = ko.computed(() => {
			let filters = [];
			
			if (this.filter.selected_states().length > 0)
				filters.push(`<span class="ps-label-primary">States: ${this.filter.selected_states().map(state => state.state).join(', ')}</span>`);
			
			if (this.filter.selected_users().length > 0)
				filters.push(`<span class="ps-label-primary">Assignees: ${this.filter.selected_users().map(user => user.username).join(', ')}</span>`);
			
			if (this.filter.selected_categories().length > 0)
				filters.push(`<span class="ps-label-primary">Categories: ${this.filter.selected_categories().map(category => category.name).join(', ')}</span>`);
			
			if (this.filter.selected_folders().length > 0)
				filters.push(`<span class="ps-label-primary">Folders: ${this.filter.selected_folders().map(folder => folder.name).join(', ')}</span>`);
			
			if (this.filter.selected_channels().length > 0)
				filters.push(`<span class="ps-label-primary">Channels: ${this.filter.selected_channels().map(channel => channel.name).join(', ')}</span>`);

			if (this.filter.from_date())
				filters.push(`<span class="ps-label-primary">From Date: ${this.filter.from_date()}</span>`);
		
			if (this.filter.to_date())
				filters.push(`<span class="ps-label-primary">To Date: ${this.filter.to_date()}</span>`);

			return filters.join('<span style="margin-left: 0.5em;"></span>');
		});
	}

	sort_column (column)
	{
		
		if (this.selected_sort_option() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.selected_sort_option(column);
			this.sort_order('ASC');
		}

		this.page.updateData();
	}

	btn_page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_toggle_visibility ()
	{
		let sidebar = document.getElementById('resizable-sidebar-folders');
		let ul = document.getElementById('resizable-ul-folders');

		if (this.side_nav_visible())
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(false);
			});

			sidebar.style.width = '2.3em';
			ul.style.width = '2.3em';
		}
		else
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(true);
			});

			sidebar.style.width = '17em';
			ul.style.width = '17em';
		}

		this.side_nav_visible(!this.side_nav_visible());
	}

	open_side_nav_main_item(side_nav_main_item)
	{
		let sidebar = document.getElementById('resizable-sidebar-folders');
		
		if (sidebar.style.width === '2.3em' && side_nav_main_item.folder === null)
			this.btn_toggle_visibility();

		this.active_side_nav_main(side_nav_main_item);
		this.side_nav_items().forEach(item => {
			if (item === side_nav_main_item)
				item.sub_pages_visible(true);
			else
				item.sub_pages_visible(false);
		});

		if (side_nav_main_item.folder != null)
		{
			this.selected_folder(side_nav_main_item.folder);
			this.active_nav(null);
			this.active_side_nav(null);
		}
	}

	set_side_nav_page(side_nav_item)
	{
		this.active_side_nav(side_nav_item);

		if (side_nav_item.folder != null)
		{
			this.navigate(side_nav_item.folder);
			this.active_nav(null);
		}
	}

	item_click(item, filter)
	{
		const item_idx = filter().indexOf(item);

		if (item_idx > -1)
			filter.splice(item_idx, 1);
		else
			filter.push(item);
	}

	set_nav_page(nav_item, folder)
	{
		this.navigate(folder);
		this.active_nav(nav_item);
		this.active_side_nav(null);

		this.side_nav_items().forEach(item => {
			if (item === this.active_side_nav_main())
				item.sub_pages_visible(false);
		});
		this.active_side_nav_main(null);
	}

	async btn_nav_to_query(data)
	{
		Grape.navigate('[/ui/engagements/]query', { data });
	}
}

/**
 * Queries page class
 */
class QueriesClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new QueriesViewModel(this);
		this.name = 'QueriesClass';
	}

	async init()
	{
		this.get_folders();

		if (this.viewModel.type() === 'my-queries')
			this.viewModel.title('My Queries');

		const states = await Grape.cache.fetch('QueryStatesLookup');
		this.viewModel.states(states);

		const channels = await Grape.cache.fetch('ChannelTypes');
		this.viewModel.channels(channels);

		const users = await Grape.cache.fetch('Users');
		this.viewModel.users(users);

		const categories = await Grape.cache.fetch('MessageCategory');
		this.viewModel.categories(categories);

		const folders = await Grape.cache.fetch('Folders');
		this.viewModel.folders(folders);
	}

	async updateData()
	{
		try 
		{
			// GET QUERIES
			let queries = {
				schema:'queries', 
				table:'v_queries', 
				filter_join: 'AND', 
				join: 'OR',
				offset: 0,
				filter: [],
				sortfield: this.viewModel.selected_sort_option(),
				sortorder: this.viewModel.sort_order()
			};

			if (this.viewModel.search_text() && this.viewModel.search_text() != '')
			{
				queries.filter = [];
				queries.filter_join = 'OR';

				queries.filter.push(
					{
						field: 'channel',
						operand: 'ILIKE',
						value: `%${this.viewModel.search_text()}%`
					},
					{
						field: 'query_refnr',
						operand: 'ILIKE',
						value: `%${this.viewModel.search_text()}%`
					},
					{
						field: 'state',
						operand: 'ILIKE',
						value: `%${this.viewModel.search_text()}%`
					},
					{
						field: 'assignee',
						operand: 'ILIKE',
						value: `%${this.viewModel.search_text()}%`
					}
				);
			}
			else
				queries.filter_join = 'AND';

			if (this.viewModel.type() === 'my-queries')
				queries.filter.push(
					{
						field: 'assignee',
						operand: '=',
						value: Grape.currentSession.user.username
					}
				);
			
			if (this.viewModel.filter.selected_users().length > 0 && this.viewModel.type() !== 'my-queries')
				queries.filter.push(
					{
						field: 'assignee',
						operand: 'IN',
						value: this.viewModel.filter.selected_users().map(user => user.username)
					}
				);

			if (this.viewModel.filter.selected_states().length > 0)
				queries.filter.push(
					{
						field: 'state',
						operand: 'IN',
						value: this.viewModel.filter.selected_states().map(item => item.state)
					}
				);

			if (this.viewModel.filter.selected_channels().length > 0)
				queries.filter.push(
					{
						field: 'channel',
						operand: 'IN',
						value: this.viewModel.filter.selected_channels().map(item => item.name)
					}
				);

			let date_from = this.viewModel.filter.from_date();
			let date_to = this.viewModel.filter.to_date();
	
			if (date_from && !date_to)
				queries.filter.push(
					{
						field: 'envelope_date',
						operand: '>=',
						value: date_from
					}
				);
			else if (!date_from && date_to)
				queries.filter.push(
					{
						field: 'envelope_date',
						operand: '<=',
						value: date_to
					}
				);
			else if (date_from && date_to)
				queries.filter.push(
					{
						field: 'envelope_date',
						operand: '>=',
						value: date_from
					},
					{
						field: 'envelope_date',
						operand: '<=',
						value: date_to
					}
				);
			
			// LOGIC: Pagination
			if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
			{
				queries.limit = this.viewModel.current_page_size();
				queries.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
			}

			let res = await Grape.fetches.getJSON('/api/record', queries);
			if (res.status !== 'OK')
				throw new Error('Error occurred while loading queries.');

			let temp_queries = [];
			for (let query of res.records)
				temp_queries.push(new Query(query));

			this.viewModel.page_count(Math.floor(res.total/res.limit)+1);
			this.viewModel.queries(temp_queries);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async get_folders()
	{
		// GET FOLDERS
		let folders = [];
		try 
		{
			let res = await Grape.tables.select({ schema: 'messages', table: 'folder' });

			if (res.status !== 'OK')
				throw new Error('Error occurred while loading folders.');
			else
				folders = res.records.map(record => ({
					...record,
					folder: record.name || null,
					title: record.name || '.',
					icon: '',
					sub_pages: [], // Add sub folders
					sub_pages_visible: ko.observable(false),
					data_visible_roles: 'all' // Add record.data_visibility_roles
				}));
		} catch (error) {
			Grape.alerts.alert({ type: 'Error', message: 'Error occurred while loading folders.', title: 'Error' });
			console.error(error);
		}

		this.viewModel.side_nav_items(folders);
	}
}

export default {
	route: '[/]ui/engagements/queries/:type',
	page_class: QueriesClass,
	template: template
}

