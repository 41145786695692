import template from './query-volumes-dashboard-panel.html';

class QueryVolumesDBPanelViewModel
{
	constructor (params)
	{
		this.params = params;

		console.log('params in volume panel: ', this.params);
		
		this.title = ko.observable('None');
		this.filter = ko.observable();
		this.date_start = ko.observable(new moment().startOf('day').subtract(5, 'days').format('YYYY-MM-DD'));
		this.date_end = ko.observable(new moment().endOf('day').format('YYYY-MM-DD'));
		this.time_span_interval = ko.observable(1);
		this.time_span = ko.observable('day(s)');

		this.init();
	}

	init ()
	{
		console.log('init in volumes panel running');

		let filters = {
			filter: [
				{field: 'date_start', 'operator': '>=', value: this.date_start()},
				{field: 'date_start', 'operator': '<', value: this.date_end()},
				{field: 'time_span_interval', 'operator': '=', value: this.time_span_interval()},
				{field: 'time_span', 'operator': '=', value: this.time_span()}
			],
			filter_join: 'AND'
		};

		// TODO add when no for DEMO
		// this.filter(filters);
		this.filter({});

	}

}


export default {
	name: 'query-volumes-dashboard-panel',
	viewModel: QueryVolumesDBPanelViewModel,
	module_type: 'ko',
	template: template
}
