
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
// import plugins from './plugins/index.js';

import package_json from '../package.json';

/* Import local css */
import './css/queries.css';

(function() {

	window.Grape.modules.push(package_json);

	Grape.dialog.registerDialogs(dialogs);
	Grape.component.registerComponents(components);
	Grape.pages.registerPages(pages);
	Grape.cache.registerCaches(appcaches);

	// Grape.plugins.registerPlugins(plugins);
	
	/* Register dashboard panel types */
	Grape.registry.addItem(
		'DashboardPanelType',
		'queries-key-indicators',
		{
			name: 'queries-key-indicators',
			component: 'query-kpi-dashboard-panel',
			title: 'Key Indicators',
			ui_fields: []
		}
	);
	Grape.registry.addItem(
		'DashboardPanelType',
		'queries-age-analysis',
		{
			name: 'queries-age-analysis',
			component: 'query-age-analysis-dashboard-panel',
			ui_fields: []
		});
	Grape.registry.addItem(
		'DashboardPanelType',
		'queries-volumes',
		{
			name: 'queries-volumes',
			component: 'query-volumes-dashboard-panel',
			title: 'Query Volumes',
			actions: [
				{name: 'share-graph', icon: 'fa fa-share-nodes', title: 'Share Graph'},
				{name: 'print', icon: 'fa fa-print', title: 'Print'},
				{name: 'download', icon: 'fa fa-file-pdf', title: 'Download'},
				{name: 'expand', icon: 'fa fa-up-right-and-down-left-from-center', title: 'Expand'}
			],
			ui_fields: []
		}
	);


	/* Register Contact View Tabs */
	window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Support', { component: 'ko-contact-queries'});
	window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Notes', { component: 'ko-contact-notes'});
})();

